import React from "react";

import "./App.css";
import "antd/dist/antd.css";
import Loadable from "react-loadable";
import { Spin } from "antd";

import Login from "./Components/Login.js";
import { useAuth } from "./context/AuthContext";

const LoadableComponent = Loadable({
  loader: () => import("./Components/PanelTabs.js"),
  loading: Spin,
});

function App() {
  const { token } = useAuth();

  return token ? <LoadableComponent /> : <Login />;
}

export default App;
