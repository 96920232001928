import React, { useState, useRef, useLayoutEffect } from "react";

import { Row, Col, Tabs, Card, Input, Form, Button, message } from "antd";
import { Margin } from "styled-components-spacing";
import Center from "react-center";
import { API_URL } from "../constants";
import useAxios from "@use-hooks/axios";
import Loadable from "react-loadable";
import { useAuth } from "../context/AuthContext";

import {} from "antd";

import "./Fonts/Fonts.css";

const Login = () => {
  const { authDispatch } = useAuth();

  const [form] = Form.useForm();
  const [postvalues, setPostValues] = useState();

  const postRes = useAxios({
    url: `${API_URL}/rest-auth/login/`,
    method: "POST",
    options: {
      data: postvalues,
    },
    //     trigger: postvalues,

    //     forceDispatchEffect: () => !!postvalues,
    customHandler: (err, res) => {
      if (res) {
        console.log("rest", res.data.key);
        authDispatch({ type: "LOGIN", token: res.data.key });
      }
      if (err) {
        message.error("wrong user or password");
      }
    },
  });

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    console.log("here");

    postRes.reFetch();
  }, [postvalues]);
  const onFinish = (values) => {
    setPostValues({
      username: values.username,
      password: values.password,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Row>
        <Col span={8}></Col>
        <Col span={8}>
          <Margin top={6}></Margin>
          <Card>
            <Center>
              <img style={{ width: "50%" }} src={require("./Imgs/logo.png")} />
            </Center>
            <Margin top={3}></Margin>
            <Form
              onFinish={onFinish}
              form={form}
              onFinishFailed={onFinishFailed}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input placeholder="username" />
                  </Form.Item>
                </Col>
              </Row>
              <Margin top={3}></Margin>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="password" />
                  </Form.Item>
                </Col>
              </Row>
              <Margin top={3}></Margin>
              <Center>
                <Button htmlType="submit" type="primary" style={{fontFamily:"IranSans"}}>
                  ورود
                </Button>
              </Center>
            </Form>
          </Card>
        </Col>
        <Col span={8}></Col>
      </Row>
    </div>
  );
};
export default Login;
